<template>
    <form action="#" autocomplete="off" v-on:submit.prevent="save">
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Contact</p>
            </header>
            <section class="modal-card-body">
                <div class="columns">
                    <div class="column">
                        <label class="label">First Name</label>
                        <input type="text"
                               name="first_name"
                               class="input"
                               v-model="contact.name"
                               v-validate="'required|min:4'">
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <label class="label">Last Name</label>
                        <input type="text"
                               class="input"
                               name="last_name"
                               v-model="contact.last_name"
                               v-validate="'required|min:4'">
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <label class="label">Phone</label>
                        <input type="text"
                               class="input"
                               name="phone"
                               v-model="contact.phone"
                               v-validate="'required'">
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <label class="label">Email</label>
                        <input type="email"
                               class="input"
                               name="email"
                               v-model="contact.email"
                               v-validate="'required'">
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot modal-foot-right">
                <button type="button" class="button" @click="$parent.close()">
                    <span class="has-text-weight-bold is-uppercase">Close</span>
                </button>
                <button type="submit" class="button is-warning">
                    <b-icon pack="far" icon="save"></b-icon>
                    <span class="has-text-weight-bold is-uppercase">Save</span>
                </button>
            </footer>
        </div>
    </form>
</template>
<script>
import ClientContact from "../../models/client/clientContact.model";

export default {
    props: [
        'model',
        'index'
    ],
    data() {
        return {
            contact: new ClientContact()
        };
    },
    watch: {
        contact: function() {
            this.$validator.reset();
        }
    },
    methods: {
        save () {
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.$emit('onSaved', {
                        contact: this.contact,
                        contactIndex: this.index
                    });
                    this.$parent.close();
                }
            });
        }
    },
    mounted() {
        this.contact.fill(this.model)
    }
}
</script>
<style>
.modal-content, .modal-card {
    overflow: visible;
}
.modal-card-body {
    overflow:visible;
}
</style>