<template>
  <section>
    <vue-headful :title="`FLPN Aviasolutions - ${pageTitle}`"></vue-headful>
    <h1 class="title">{{ pageTitle }}</h1>
    <contenedor>
      <form action="#" autocomplete="off" v-on:submit.prevent="save">
        <div class="columns">
          <div class="column is-12">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  Client Configuration
                </p>
              </header>
              <div class="card-content">
                <div class="columns">
                  <div class="column is-4">
                    <label class="label">Name</label>
                    <input type="text"
                           class="input"
                           name="name"
                           v-model="client.name"
                           v-validate="'required|min:4'">
                  </div>
                  <div class="column is-4">
                    <label class="label">Description</label>
                    <input type="text"
                           class="input"
                           v-model="client.description">
                  </div>
                  <div class="column is-4">
                    <label class="label">Operator Name</label>
                    <input type="text"
                           class="input"
                           v-model="client.operator_name">
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-3 is-fullwidth">
                    <b-field label="Expiration Period">
                      <datepicker placeholder="Select expiration date" :config="{ dateFormat: 'm/d/Y', static: true }"
                                  v-model="client.expiration_period"></datepicker>
                      <!-- <datepicker placeholder="Select expiration date" v-model="client.expiration_period"></datepicker> -->
                    </b-field>
                  </div>
                  <div class="column is-3">
                    <b-field label="Logo">
                      <b-field class="file is-fullwidth is-right">
                                            <span class="file-name">
                                                {{ client.logo ? client.logo.name : '' }}
                                            </span>
                        <b-upload v-model="selectedLogo" accept="image/*">
                          <a class="button is-danger">
                            <b-icon icon="upload"></b-icon>
                            <span>Choose a logo…</span>
                          </a>
                        </b-upload>
                      </b-field>
                    </b-field>
                  </div>
                  <div class="column is-2">
                    <b-field label="Suspended">
                      <b-switch
                          v-model="client.is_suspended"
                          type="is-danger">
                        {{ client.is_suspended ? 'YES' : 'NO' }}
                      </b-switch>
                    </b-field>
                  </div>
                  <div class="column is-2" v-if="$auth.check(['admin'])">
                    <b-field label="Report Format">
                      <b-select v-model="client.report_format_id" placeholder="Select a Report Format" expanded
                                required>
                        <option
                            v-for="format in reportFormats"
                            :value="format.id"
                            :key="format.id">
                          {{ format.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="column is-2" v-if="$auth.check(['admin'])">
                    <b-field label="Wind Format">
                      <b-select v-model="client.wind_format_id" placeholder="Select a Wind Summary Format" expanded
                                required>
                        <option
                            v-for="format in windFormats"
                            :value="format.id"
                            :key="format.id">
                          {{ format.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-3">
                    <b-field label="Display Metar by default">
                      <b-switch
                          v-model="client.display_metar_by_default"
                          type="is-danger">
                        {{ client.display_metar_by_default == true ? 'YES' : 'NO' }}
                      </b-switch>
                    </b-field>
                  </div>
                  <div class="column is-3">
                    <b-field label="Display Taf by default">
                      <b-switch
                          v-model="client.display_taf_by_default"
                          type="is-danger">
                        {{ client.display_taf_by_default == true ? 'YES' : 'NO' }}
                      </b-switch>
                    </b-field>
                  </div>
                  <div class="column is-3">
                    <b-field label="Display Notam by default">
                      <b-switch
                          v-model="client.display_notam_by_default"
                          type="is-danger">
                        {{ client.display_notam_by_default == true ? 'YES' : 'NO' }}
                      </b-switch>
                    </b-field>
                  </div>
                  <div class="column is-3">
                    <b-field label="Notam Format">
                      <div class="block">
                        <b-radio v-model="client.display_raw_notam"
                                 name="display raw"
                                 :native-value="true">
                          RAW
                        </b-radio>
                        <b-radio v-model="client.display_raw_notam"
                                 name="display report"
                                 :native-value="false">
                          REPORT
                        </b-radio>
                      </div>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">Contacts</p>
              </header>
              <div class="card-content">
                <div class="columns">
                  <div class="column is-2 is-offset-5" v-if="!client.hasContacts || !client.hasMaxContacts">
                    <button class="button is-info is-fullwidth" type="button" @click="onAddContact">
                      <i class="fa fa-plus"></i>&nbsp; Add a Contact
                    </button>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6" v-for="(contact, contactIndex) in client.contacts" :key="contactIndex">
                    <client-contact-card :contactIndex="contactIndex"
                                         :contact="contact"
                                         @onEdit="onEditContact"
                                         @onDelete="onDeleteContact">
                    </client-contact-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-grouped is-grouped-right">
          <p class="control">
            <router-link :to="'/clients'" class="button is-light">
              <b-icon pack="fas" icon="long-arrow-alt-left"></b-icon>
              <span class="has-text-weight-bold is-uppercase">Back to Clients</span>
            </router-link>
          </p>
          <div class="control" v-if="$auth.check(['admin'])">
            <button type="button" class="button is-danger" @click="dropClient()">
              <span class="has-text-weight-bold is-uppercase">Delete</span>
            </button>
          </div>
          <p class="control">
            <button type="submit" class="button is-primary">
              <b-icon pack="far" icon="save"></b-icon>
              <span class="has-text-weight-bold is-uppercase">Save</span>
            </button>
          </p>
        </div>
      </form>
    </contenedor>
  </section>
</template>
<script>
import ClientsService from "../../services/clients.service";
import FileUploadService from "../../services/file-upload.service";
import Client from "../../models/client/client.model";
import ClientContactCard from "./ClientContactCard.vue";
import ClientContactModal from "./ClientContactModal";
import FailedResponse from "../../helpers/failed-response.helper";
import Datepicker from 'vue-bulma-datepicker'
import ReportFormatsService from "../../services/report-formats.service";
import WindFormatsService from "../../services/wind-formats.service";
import Contenedor from "@/components/Contenedor";


export default {
  name: 'ClientForm',
  components: {
    Contenedor,
    ClientContactCard,
    Datepicker
  },
  data() {
    return {
      pageTitle: 'Create Client',
      clientsService: new ClientsService(),
      fileUploadService: new FileUploadService(),
      client: new Client(),
      selectedLogo: null,
      editingContact: false,
      reportFormatsService: new ReportFormatsService(),
      windFormatsService: new WindFormatsService(),
      reportFormats: [],
      windFormats: []

    };
  },
  watch: {
    '$route'(to) {
      if (to.path === '/clients/create') {
        this.reset();
      }
    },
    selectedLogo: function (newLogo) {
      if (newLogo) {
        this.client.logo = {
          name: newLogo.name
        };
      }
    }
  },
  methods: {
    reset: function () {
      this.client = new Client();
      this.$validator.reset();
    },
    onAddContact() {
      this.$buefy.modal.open({
        parent: this,
        component: ClientContactModal,
        width: 400,
        canCancel: false,
        events: {
          onSaved: (e) => {
            this.client.addContact(e.contact)
          }
        }
      })
    },
    onEditContact(e) {
      this.$buefy.modal.open({
        parent: this,
        component: ClientContactModal,
        props: {
          model: e.contact,
          index: e.contactIndex
        },
        width: 400,
        canCancel: false,
        events: {
          onSaved: (e) => {
            this.client.contacts[e.contactIndex] = e.contact
          }
        }
      })
    },
    onDeleteContact(e) {
      this.client.removeContact(e.contactIndex)
    },
    save: function () {
      this.$validator.validate().then(valid => {
        if (valid) {
          let isNew = true
          let urlClient = 'clients'
          if (this.client.exists) {
            isNew = false
            urlClient = `clients/${this.client.id}`
          }
          const loadingComponent = this.$buefy.loading.open({container: null})
          this.fileUploadService.upload({
            url: urlClient,
            postMethod: isNew,
            file: this.selectedLogo,
            extraData: this.client.toJSON()
          }).then(
              ({data}) => {
                this.client.fill(data.client)
                loadingComponent.close();
                this.$buefy.toast.open({
                  message: data.notificacion,
                  type: 'is-success'
                });
                this.clearInputLogo();
              },
              (failed) => {
                loadingComponent.close();
                const messages = FailedResponse.getMessage(failed);
                this.$buefy.toast.open({
                  duration: 5000,
                  message: messages,
                  type: 'is-danger'
                });
              }
          );
        }
      });
    },
    dropClient: function () {
      this.clientsService.delete(this.client.id).then(() => {
        this.$router.push(`/clients`);
        this.$buefy.toast.open({
          message: 'Client deleted successfully',
          type: 'is-success'
        });
      })
    },
    clearInputLogo() {
      this.selectedLogo = null;
    }
  },
  mounted() {
    this.reportFormatsService.findAll().then(
        ({data}) => {
          this.reportFormats = data;
        }
    );
    this.windFormatsService.findAll().then(
        ({data}) => {
          this.windFormats = data;
        }
    );
    if (this.$route.params.id) {
      const loadingComponent = this.$buefy.loading.open({container: null})
      this.clientsService.findOne(this.$route.params.id).then(
          ({data}) => {
            this.client.fill(data.data);
            // if (data.expiration_period) {
            //     this.client.expiration_period = this.$moment(data.expiration_period).toDate()
            // }
            this.pageTitle = `Edit Client: ${data.data.name}`;
            loadingComponent.close();
          },
          () => {
            loadingComponent.close();
            this.$buefy.toast.open({
              message: 'Client not found.',
              type: 'is-danger'
            });
          }
      );
    }
  }
}
</script>
<style>
.card-header {
  background-color: lightgrey;
}
</style>