import Model from "../model"

export default class ClientContact extends Model
{
    constructor(attr = null) {
        super();
        this.name = null;
        this.last_name = null;
        this.phone = null;
        this.email = null;
        if (attr) {
            this.fill(attr)
        }
    }
    toJSON() {
        const contact = {
            name: this.name,
            last_name: this.last_name,
            phone: this.phone,
            email: this.email
        };
        if (this.exists) {
            contact.id = this.id;
        }
        return contact;
    }
    static createMany(collection) {
        return collection.map(function(item) {
            return new ClientContact(item);
        });
    }
}