import Model from "../model"

export default class Fleet extends Model
{
    constructor(attr = null) {
        super();
        this.id = null;
        this.name = null;
        this.taxi_in = 200;
        this.taxi_out = 200;
        this.min_fuel_wt = 200;
        this.min_fuel_pct = 5;
        if (attr) {
            this.fill(attr)
        }
    }
    // ---------------------------- METODOS ---------------------------
    toJSON() {
        const fleet = {
            id: this.id,
            taxi_in: this.taxi_in,
            taxi_out: this.taxi_out,
            min_fuel_wt: this.min_fuel_wt,
            min_fuel_pct: this.min_fuel_pct
        };
        return fleet;
    }
    static createMany(collection) {
        return collection.map(function(item) {
            return new Fleet(item);
        });
    }
}