import Vue from 'vue'
// import { EventBus } from '../event-bus.js';

export default class FileUploadService
{
    constructor() {
        this.uploadOptions = {};
    }
    // ---------------------------- GETTERS ---------------------------
    get host() {
        return process.env.VUE_APP_HOST;
    }
    get url() {
        return `${this.host}/${this.uploadOptions.url}`;
    }
    get http() {
        // The 'http' service can be used globally Vue.http or in a Vue instance this.$http.
        return Vue.http;
    }
    // ---------------------------- METODOS ---------------------------
    upload (options) {
        this.uploadOptions = this.getOptions(options);
        this.checkOptions();
        const formData = new FormData();

        if (this.uploadOptions.file) {
            formData.append(this.uploadOptions.inputFileName, this.uploadOptions.file, this.uploadOptions.file.name);
        }

        if (this.uploadOptions.extraData) {
            const extraData = this.uploadOptions.extraData;
            /*
            for (const field of Object.keys(extraData)) {
                const value = extraData[field];
                if (!(value instanceof File)) {
                    if (Array.isArray(value)) {
                        // const array = JSON.stringify(value)
                        // formData.append(field, array);
                    } else {
                        formData.append(field, !!value ? value : '');
                    }
                }
            }*/
            this.setFormData(formData, extraData);
        }

        // for( var i = 0; i < this.uploadOptions.files.length; i++ ){
        //     let file = this.uploadOptions.files[i];

        //     formData.append('files[' + i + ']', file);
        // }

        if (!this.uploadOptions.postMethod) {
            formData.append('_method', 'PUT');
        }

        return this.http.post(this.url, formData, {
            progress : e => {
                /*
                if (e.lengthComputable && this.uploadOptions.progressKey) {

                    EventBus.$emit(
                        'progresoAdjunto',
                        {
                            porcentaje: Math.floor(e.loaded / e.total * 100),
                            id: this.uploadOptions.progressKey
                        }
                    );
                }*/
            },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    setFormData(formData, data, previousKey) {
        Object.keys(data).forEach(key => {
            const value = data[key];
            if (previousKey) {
                key = `${previousKey}[${key}]`;
            }
            if (Array.isArray(value)) {
                const collection = value
                collection.forEach((item, index) => {
                    this.setFormData(formData, item, `${key}[${index}]`);
                })
            } else {
                // Para no tener que tratar estos casos en el Modelo, si son booleanos se entrega 1 o 0.
                if (typeof data[key] === "boolean"){
                    formData.append(key, value ? 1 : 0);
                } else {
                    formData.append(key, !!value ? value : '');
                }
            }
        });
    }
    /*
    setFormData2(formData, data, previousKey) {
        if (data instanceof Object) {
            Object.keys(data).forEach(key => {
                const value = data[key];
                if (value instanceof Object && !Array.isArray(value)) {
                    return this.setFormData(formData, value, key);
                }
                if (previousKey) {
                    key = `${previousKey}[${key}]`;
                }
                if (Array.isArray(value)) {
                    value.forEach(val => {
                        formData.append(`${key}[]`, val);
                    });
                } else {
                    formData.append(key, value);
                }
            });
        }
    }*/

    getOptions(options) {
        const defaults = {
            url: null,
            inputFileName: 'file',
            file: null,
            // files: [],
            postMethod: true,
            extraData: null,
            progressKey: null
        };
        return Object.assign(defaults, options);
    }
    /**
     * Verificar opciones
     */
    checkOptions() {
        if (this.uploadOptions.url == null) {
            throw "No se ha especificado una 'URL' en 'FileUploadService'.";
        }

        if (this.uploadOptions.file != null && !(this.uploadOptions.file instanceof File)) {
            throw "La opcion 'file' debe ser del tipo 'File' en 'FileUploadService'.";
        }

        // if (this.uploadOptions.files != null && !(this.uploadOptions.files instanceof Array)) {
        //     throw "La opcion 'files' debe ser del tipo 'Array' en 'FileUploadService'.";
        // }

        if (this.uploadOptions.extraData != null && !(this.uploadOptions.extraData instanceof Object)) {
            throw "La opcion 'extraData' debe ser del tipo 'Object' en 'FileUploadService'.";
        }
    }
}