export default class FailedResponse
{
    static getMessage(response) {
        if (response.status === 422) {
            let messages = '';
            for (const field of Object.keys(response.body)) {
                const concatMessages = response.body[field].toString(); // 'a,b,c'
                messages +=`${field}: ${concatMessages}`;
            }
            return messages;
        } else if (typeof(response.body) == 'object') {
            return response.body.error
        }
        return 'Errors Found!!!';
    }
}