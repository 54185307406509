import Model from "../model"
import Attachment from "../attachment"
import Fleet from "./fleet.model"
import ClientContact from "./clientContact.model"

export default class Client extends Model
{
    constructor(attr = null) {
        super();
        this._logo = null;
        this._fleets = [];
        this._contacts = []; 
        this.name = null;
        this.description = null;
        this.operator_name = null;
        this.expiration_period = null;
        this.report_format_id = null;
        this.wind_format_id = null;
        this.is_suspended = false;
        this.display_metar_by_default = false;
        this.display_taf_by_default = false;
        this.display_notam_by_default = false;
        this.display_raw_notam = false;
        if (attr) {
            this.fill(attr)
        }
    }
    // ---------------------------- SETTERS ---------------------------
    set fleets(value) {
        this._fleets = Fleet.createMany(value);
    }

    set contacts(value) {
        this._contacts = ClientContact.createMany(value);
    }

    set logo (model) {
        this._logo = new Attachment(model)
    }
    
    // ---------------------------- GETTERS ---------------------------
    get fleets() {
        return this._fleets;
    }

    get contacts() {
        return this._contacts;
    }

    get logo () {
        return this._logo
    }

    get hasFleets() {
        return this._fleets.length > 0;
    }

    get hasContacts() {
        return this._contacts.length > 0;
    }

    get hasMaxContacts() {
        return this._contacts.length == 2;
    }
    // ---------------------------- METODOS ---------------------------
    addContact(contactModel) {
        this.contacts.push(contactModel)
    }
    removeContact(contactIndex) {
        this.contacts.splice(contactIndex, 1)
    }
    toJSON() {
        const client = {
            name: this.name,
            description: this.description,
            operator_name: this.operator_name,
            is_suspended: this.is_suspended,
            expiration_period: this.expiration_period,
            report_format_id: this.report_format_id,
            wind_format_id: this.wind_format_id,
            display_metar_by_default: this.display_metar_by_default,
            display_taf_by_default: this.display_taf_by_default,
            display_notam_by_default: this.display_notam_by_default,
            display_raw_notam: this.display_raw_notam,
            fleets: this.fleets
        };
        // if (this.expiration_period) {
        //     if (this.expiration_period instanceof Date) {
        //         client.expiration_period = this.expiration_period.toLocaleDateString()
        //     }
        // }
        if (this.hasContacts) {
            client.contacts = this._contacts.map(function(contact) {
                return contact.toJSON()
            })
        }
        if (this.exists) {
            client.id = this.id;
        }
        return client;
    }
}