<template>
    <div class="card">
        <div class="card-content">
            <div class="content">
                <p class="title">{{ contact.last_name }}, {{ contact.name }}</p>
                <p>
                    Phone: <strong>{{ contact.phone }}</strong>
                    <br><br>
                    Email: <strong>{{ contact.email }}</strong>
                    <br><br>
                    <time>Updated at: {{ contact.updated_at }}</time>
                </p>
            </div>
        </div>
        <footer class="card-footer">
            <a href="javascript:;" class="card-footer-item" @click=onEdit()>
                <i class="fa fa-edit"></i> Edit
            </a>
            <a href="javascript:;" class="card-footer-item" @click=onDelete()>
                <i class="fa fa-trash"></i> Delete
            </a>
        </footer>
    </div>        
</template>
<script>

export default {
    props: ['contact', 'contactIndex'],
    data() {
        return {};
    },
    methods: {
        onEdit () {
            this.$emit('onEdit', {
                contactIndex: this.contactIndex,
                contact: this.contact
            });
        },
        onDelete () {
            this.$emit('onDelete', {
                contactIndex: this.contactIndex,
            });
        }
    }
}
</script>