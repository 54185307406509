import Model from "./model"

export default class Attachment extends Model
{
    constructor(attr = null) {
        super();
        this.id = null
        this.name = null
        this.encrypted_name = null
        this.in_storage = false
        this.url = null
        this.content_type = null
        if (attr) {
            this.fill(attr)
        }
    }
}